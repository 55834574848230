import { select, takeLatest } from 'redux-saga/effects';
import {
  videoPlayStarted,
  videoPaused,
  videoSeekStarted,
  videoSeeked,
  qualityChanged,
  volumeChanged,
  speedChanged,
  registerPlayerExternalApi,
  updateTime,
  getSpeedOptions,
  getQualityOptions,
} from '../actions';
import { FromPlayerEmitter } from '../../../api/external/emitters/FromPlayerEmitter';
import { getFile } from '../../sourceConfiguration/selectors';
import { getQuality, getSpeed } from '../selectors';
import { speedOptions } from '../../../components/Controls/SelectSpeed/SelectSpeed';
import { toQualityOptions } from '../../../components/Controls/SelectQuality/SelectQuality';

function handleVideoPlayStarted(emitter: FromPlayerEmitter) {
  emitter?.videoPlayStarted.emit();
}

function handleVideoPaused(emitter: FromPlayerEmitter) {
  emitter?.videoPaused.emit();
}

function handleVideoSeekStarted(
  emitter: FromPlayerEmitter,
  action: ReturnType<typeof videoSeekStarted>
) {
  emitter?.videoSeekStarted.emit({
    time: action.payload.toTime,
  });
}

function handleVideoSeeked(
  emitter: FromPlayerEmitter,
  action: ReturnType<typeof videoSeeked>
) {
  emitter?.videoSeeked.emit({
    time: action.payload.time,
  });
}

function handleQualityChanged(
  emitter: FromPlayerEmitter,
  action: ReturnType<typeof qualityChanged>
) {
  emitter?.qualityChanged.emit({
    quality: action.payload.quality,
  });
}

function handleVolumeChanged(
  emitter: FromPlayerEmitter,
  action: ReturnType<typeof volumeChanged>
) {
  emitter?.volumeChanged.emit({
    volume: action.payload.volume,
    muted: action.payload.muted,
  });
}

function handleSpeedChanged(
  emitter: FromPlayerEmitter,
  action: ReturnType<typeof speedChanged>
) {
  emitter?.speedChanged.emit({
    speed: action.payload,
  });
}

function handleUpdateTime(
  emitter: FromPlayerEmitter,
  action: ReturnType<typeof updateTime>
) {
  emitter?.timeUpdate.emit({
    time: action.payload.newTime,
  });
}

function* handleGetSpeedOptions(
  emitter: FromPlayerEmitter,
  _action: ReturnType<typeof getSpeedOptions>
) {
  const speed = getSpeed(yield select());

  emitter?.speedOptions.emit({ options: speedOptions, current: speed });
}

function* handleGetQualityOptions(
  emitter: FromPlayerEmitter,
  _action: ReturnType<typeof getQualityOptions>
) {
  const file = getFile(yield select());
  const quality = getQuality(yield select());
  if (!file) {
    return;
  }

  emitter?.qualityOptions.emit({
    options: toQualityOptions(file),
    current: quality,
  });
}

function* listenActions(action: ReturnType<typeof registerPlayerExternalApi>) {
  const emitter = action.payload.api?.fromEmitter;

  yield takeLatest(videoPlayStarted, handleVideoPlayStarted, emitter);
  yield takeLatest(videoPaused, handleVideoPaused, emitter);
  yield takeLatest(videoSeekStarted, handleVideoSeekStarted, emitter);
  yield takeLatest(videoSeeked, handleVideoSeeked, emitter);
  yield takeLatest(qualityChanged, handleQualityChanged, emitter);
  yield takeLatest(volumeChanged, handleVolumeChanged, emitter);
  yield takeLatest(speedChanged, handleSpeedChanged, emitter);
  yield takeLatest(updateTime, handleUpdateTime, emitter);
  yield takeLatest(getSpeedOptions, handleGetSpeedOptions, emitter);
  yield takeLatest(getQualityOptions, handleGetQualityOptions, emitter);
}

export function* playerEventsSaga() {
  yield takeLatest(registerPlayerExternalApi, listenActions);
}
