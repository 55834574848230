import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Provider, useDispatch, useSelector } from 'react-redux';
import { usePreviousDistinct } from 'react-use';
import { isEqual } from 'lodash-es';
import { IConfiguration } from '../../types/types';
import { IPlayerExtended } from '../../types/player';
import { IPlayerVideo } from '../../types/video';
import { IFunnelPlayerAddition } from '../../types/funnels';
import { configurePlayerStore } from '../../store/rootPlayerStore';
import {
  newSourceConfigurationEmitted,
  registerCallbacks,
} from '../../store/sourceConfiguration/actions';
import {
  selectContainerHeight,
  selectContainerWidth,
} from '../../store/containerDimensions/selectors';
import { PlayerMode } from '../types/defaultPropTypes';
import { DimensionObject } from '../../hooks/useResizableRatio';
import { Player } from './Player';
import { PlayerExternalAPIHolder } from '../../api/external/PlayerExternalAPIHolder';
import { PlayerExternalAPI } from '../../api/external/PlayerExternalAPI';
import { getStoreId } from '../../store/selectors';

export type ISetResetPlayerFunction = (() => void) | undefined;
export interface ICallbacks extends IConfiguration {
  setResetPlayerFunction?: (func: ISetResetPlayerFunction) => void;
  onChangeDimensions?: (dimensions: DimensionObject) => void;
  onTimeChange?: (currentTime: number) => void;
  onNavigateToOtherNodeRequest?: (nodeId: string, timestamp?: number) => void;
  onVideoFinished?: () => void;
}

// What to play & how to play. Note: NO embedVideo at this step.
// if you have only embedVideoId, then you need PlayerContainerFromEmbedVideoId
export interface ISourceConfiguration {
  playerConfig: IPlayerExtended & Partial<IFunnelPlayerAddition>;
  file: IPlayerVideo;
  playerMode: PlayerMode;

  funnelId?: string;
  currentFunnelNodeId?: string;
  spotlightId?: string;

  // This objects applies the provided settings when changed. First object
  // (on initial rendering) will be the initial playback settings.
  // Q: why object?
  // A: because then we can apply the same settings later. E.g. change currentTime
  //    to 5 sec, wait 10 sec and then apply it again -> new object -> change detected.
  //    If we passed just 5 two times in a row, then the number wouldn't change -> nothing would have happened
  playbackSettings: IPlaybackSettings;

  withoutStatisticsTracking?: boolean;
}

// Misc appearance settings to accommodate different places of placing the player
export interface IMiscAppearance {
  extraContent?: React.ReactNode;
  playerMode: PlayerMode;
  ratio?: number;
}

// This objects applies the provided settings when changed. First object
// (on initial rendering) will be the initial playback settings.
export interface IPlaybackSettings {
  forceAutoPlay?: boolean;
  forceAspectRatio?: number;
  currentTime: number;
  // if player `resumeOnFutureVisitsEnabled=true` then shows
  // "welcome back, want to play from the beginning?"
  // if user picks "continue", it stars from this point
  lastVisitWatchedSeconds?: number;

  // When user is looking on "Controls" tab during player configuration we should
  // show all controls to them so they know what they are configuring
  allControlsAreAlwaysVisible?: boolean;

  areKeybindingsGlobal?: boolean;
  //
}

interface IOtherConfig {
  isRememberDismissEnabled: boolean;
  playerExternalAPI?: PlayerExternalAPI;
}

// How it works: feed PlayerContainer with embedVideoId OR IPlayerConfig
// It assembles an array of events (event + timestamp) what happened.
// E.g: 1st second - show annotation, 5th second - hide annotation, show CTA etc
// Player consumes that array.

export type IPlayerContainerProps = ICallbacks &
  ISourceConfiguration &
  IMiscAppearance &
  IOtherConfig;

type IProps = IPlayerContainerProps;

export const PlayerContainer = (props: IProps) => {
  const { isRememberDismissEnabled } = props;

  const [storeConfig] = useState(() => {
    return configurePlayerStore(isRememberDismissEnabled);
  });

  useEffect(() => {
    const destruct = () => {
      storeConfig.dispose();
    };

    return () => {
      destruct();
    };
  }, [storeConfig]);

  return (
    <Provider store={storeConfig.store}>
      <ContainerWithStoreAccess {...props} />
      {/* From now on we rely not on the props.file, but on store.sourceConfiguration.file! */}
      {/* That's why we need a new component here - to subscribe to the store. */}
    </Provider>
  );
};

const ContainerWithStoreAccess = ({
  ratio,
  playerConfig,
  file,
  playerMode,
  playbackSettings,
  spotlightId,
  currentFunnelNodeId,
  funnelId,
  extraContent,
  onChangeDimensions,
  onTimeChange,
  onVideoFinished,
  onNavigateToOtherNodeRequest,
  playerExternalAPI,
}: IProps) => {
  const dispatch = useDispatch();
  const storeId = useSelector(getStoreId);

  // onChangeDimensions used only for Courses, when Courses will be totally removed then this can be removed
  const containerWidth = useSelector(selectContainerWidth);
  const containerHeight = useSelector(selectContainerHeight);

  useEffect(() => {
    onChangeDimensions?.({ width: containerWidth, height: containerHeight });
  }, [onChangeDimensions, containerWidth, containerHeight]);

  const sourceConfig = useMemo(
    (): ISourceConfiguration => ({
      playerConfig,
      file,
      playerMode,
      playbackSettings,
      spotlightId,
      currentFunnelNodeId,
      funnelId,
    }),
    [
      currentFunnelNodeId,
      file,
      funnelId,
      playbackSettings,
      playerConfig,
      playerMode,
      spotlightId,
    ]
  );

  const prevSourceConfig = usePreviousDistinct(playerConfig);
  useEffect(() => {
    if (isEqual(playerConfig, prevSourceConfig)) {
      return;
    }

    dispatch(newSourceConfigurationEmitted(sourceConfig));
  }, [dispatch, playerConfig, prevSourceConfig, sourceConfig]);

  useEffect(() => {
    dispatch(
      registerCallbacks({
        onTimeChange,
        onVideoFinished,
        onNavigateToOtherNodeRequest,
      })
    );
  }, [dispatch, onNavigateToOtherNodeRequest, onTimeChange, onVideoFinished]);

  const handleRegisterVideoAPI = useCallback(() => {
    if (!playerExternalAPI) {
      return;
    }

    // Register Player API only when Video API is registered
    PlayerExternalAPIHolder.instance.register(
      storeId,
      dispatch,
      playerExternalAPI
    );
  }, [dispatch, storeId, playerExternalAPI]);

  return (
    <Player
      ratio={ratio}
      extraContent={extraContent}
      playbackSettings={playbackSettings}
      onRegisterVideoAPI={handleRegisterVideoAPI}
    />
  );
};
