import { put, select, takeLatest } from 'redux-saga/effects';
import { PlayerMode } from '../../../components/types/defaultPropTypes';
import { updateAskToResumeOnVisitOverlay } from '../../askToResumeOnVisitOverlay/actions';
import {
  getFile,
  getFunnelId,
  getIsVideoChanging,
  getPlaybackSettings,
  getPlayerConfig,
  getPlayerMode,
} from '../../sourceConfiguration/selectors';
import { getIsThumbnailVisible } from '../../thumbnail/selectors';
import { changeClickForSoundVisibility } from '../../videoControls/actions';
import { resetHighlights } from '../../videoItems/actions';
import {
  requestAutoplay,
  requestSeekAndBuffer,
  requestVolumeChange,
  videoIsReady,
} from '../actions';
import { getFunnelState } from '../../funnelState/selectors';
import { getPlayerExternalAPI } from '../../selectors';

function* handleVideoReady() {
  const player = getPlayerConfig(yield select());
  const initialPlaybackSettings = getPlaybackSettings(yield select());
  const file = getFile(yield select());
  const playerMode = getPlayerMode(yield select());

  if (getIsVideoChanging(yield select())) return;
  if (!player) return;

  yield put(resetHighlights());

  let timeToSeek = initialPlaybackSettings
    ? initialPlaybackSettings.currentTime
    : 0;

  const previousVisitTime = initialPlaybackSettings
    ? initialPlaybackSettings.lastVisitWatchedSeconds
    : undefined;

  if (file && file.metadata.duration <= timeToSeek) {
    timeToSeek = 0;
  }

  let shouldSeek = timeToSeek > 0;
  // If there's no thumbnail at the moment, then we want to seek (and load)
  // the movie so user sees something (not blank screen)
  if (!getIsThumbnailVisible(yield select())) {
    shouldSeek = true;
  }

  const { resumeOnFutureVisitsEnabled } = player.controls;
  if (!resumeOnFutureVisitsEnabled) {
    // This will hide the "Welcome overlay"
    // If for some reason askToResumeOnVisitOverlay is not empty, maybe after redux-persist
    yield put(updateAskToResumeOnVisitOverlay(undefined));
  }

  if (shouldSeek) {
    yield put(requestSeekAndBuffer({ time: timeToSeek, doubleSeek: true }));
  }

  const initialPlaybackSettingsAutoPlay =
    initialPlaybackSettings?.forceAutoPlay;
  const playerAutoplay = player.controls.autoplay;
  const isOnTimelineEditor =
    playerMode &&
    [PlayerMode.TIMELINE_EDITOR, PlayerMode.THUMBNAIL_EDITOR].includes(
      playerMode
    );
  const shouldAutoplay =
    !isOnTimelineEditor && (initialPlaybackSettingsAutoPlay || playerAutoplay);

  const isFunnel = !!getFunnelId(yield select());
  const funnelState = getFunnelState(yield select());

  const muted =
    (isFunnel ? funnelState.muted : undefined) ?? player.controls.muted;
  if (muted && shouldAutoplay) {
    yield put(changeClickForSoundVisibility({ isVisible: true }));
  }

  if (shouldAutoplay) {
    yield put(requestAutoplay({ muted }));
  } else {
    // we're not autoPlaying - controlling sound right away
    yield put(
      requestVolumeChange({
        mute: (isFunnel ? funnelState.muted : undefined) ?? false,
        type: 'mute',
      })
    );

    if (resumeOnFutureVisitsEnabled && previousVisitTime && !shouldSeek) {
      yield put(
        updateAskToResumeOnVisitOverlay({
          resumeFromSecond: previousVisitTime,
        })
      );
    }
  }

  const playerExternalApi = getPlayerExternalAPI(yield select());
  playerExternalApi?.fromEmitter.videoIsReady.emit();
}

export function* initialVideoReadySaga() {
  yield takeLatest(videoIsReady, handleVideoReady);
}
